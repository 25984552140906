.App {
  text-align: center;
  background: linear-gradient(180deg, #010409, #0f0268);
  min-height: 100vh;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1 {
  color: #2096f3;
  font-family: sans-serif;
  margin-top: 10px;
  margin-bottom: 0px;
}

h2 {
  color: #2096f3;
  font-family: sans-serif;
  margin-top: 10px;
  margin-bottom: 0px;
}

.center_horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.body {
  width: 600px;
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: 45px;
}

.form_body {
  color: #3d3d3d;
  font-family: sans-serif;
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.error {
  color: red;
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.form {
 background: white;
 border: 1px solid #dedede;
 display: flex;
 flex-direction: column;
 justify-content: space-around;
 margin: 80px auto;
 margin-top: 200px;
 width: 400px;
 padding: 20px;
}

input {
 border: 1px solid #d9d9d9;
 border-radius: 4px;
 box-sizing: border-box;
 padding: 10px;
 width: 100%;
}

label {
 color: #3d3d3d;
 display: block;
 font-family: sans-serif;
 font-size: 20px;
 font-weight: 500;
 margin-bottom: 5px;
 margin-top: 10px;
}

button {
 background-color: #428fcc;
 color: white;
 border: 2px white;
 border-radius: 4px;
 font-family: sans-serif;
 font-size: 20px;
 margin: 5px 0px;
 padding: 8px;
 cursor: pointer;

 &:disabled {
    opacity: 0.5;
    cursor: default;
 }
}

.amplify-button--primary {
  background-color: #428fcc;
}

.amplify-button--primary:hover {
  background-color: rgb(29, 55, 91);
}

.amplify-button--link {
  color: #428fcc;
}

.amplify-tabs__item--active {
  color: #428fcc;
}

[data-amplify-authenticator][data-variation=modal] {
  background-color: rgb(29, 55, 91)
}

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 50%;
  padding: 5px;
}
.popup-arrow {
  background: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.form {
  margin-top: 70px;
  margin-bottom: 5px;
  width: 400px;
}

.top-navbar {
  left: 0;
  top: 0;
  position: fixed;
  height: 56px;
  background-color: var(--color-teal-700);
  min-width: 100vw;
  padding-left: 0px;
  margin-left: 0px;
}

.bottom-navbar {
  right: 0;
  bottom: 0;
  position: fixed;
  height: 46px;
  background-color: var(--color-teal-700);
  width: 100vw;
  padding-left: 0px;
  margin-left: 0px;
}

.form-group {
  margin-bottom: 0px;
}

.left-navbar {
  position: fixed;
  top: 56px;
  bottom: 0;
  left: 0;
  min-height: 100vh;
  width: 300px;
  padding: 0 0 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  z-index: 900;
}

.right-navbar {
  position: fixed;
  top: 56px;
  bottom: 0;
  right: 0;
  min-height: 100vh;
  width: 15vw;
  padding: 0 0 0 0;
  box-shadow: inset 1px 0 0 rgba(0, 0, 0, .1);
}

/* .ppt-viewer {
  position: fixed;
  top: 80px;
  max-width: 20vw;
  z-index: 100,
} */

.form-text-area {
  width: 60% !important;
}

.demo-form-input-label {
  font-size: 14px;
}

.center-powerpoint-viewing-space {
  position: fixed;
  top: 56px;
  padding-top: 50px;
  bottom: 0;
  left: 20vw; 
  width: 80vw;
  max-height: 100%;
  overflow-y: scroll;
}

.button-container {
  position: fixed;
  width: 100vw;
  right: 20px;
  bottom: 20px;
}

.sandbox-container {
  background-color: rgb(230, 230, 230);
  height: 100vh;
  width: 100vw;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center; /* Center horizontally */
   /* Full viewport height */
}

.grid {
  display: grid;
  position: fixed;
  left: 50%; /* Position the element halfway across the screen or container */
  transform: translateX(-50%);
  top: 132px;
  gap: 0; /* Space between grid items */
  padding: 0;
  border: 1px solid #ccc;
  width: 730px;
  height: 540px; /* Adjust the height as needed */
  background-color: rgb(255, 255, 255);
}

.horizontal-tick {
  position: absolute;
  top: 0;
  height: 10px; 
  width: 1px; 
  background-color: black;
}

.vertical-tick {
  position: absolute;
  left: 0;
  width: 10px; 
  height: 1px; 
  background-color: black;
}

.tick-label {
  position: absolute;
  font-size: 10px; /* Adjust the font size as needed */
  color: black;
}

/* Positioning for horizontal tick labels */
.horizontal-tick-label {
  top: 12px; /* Position below the horizontal tick mark */
  transform: translateX(-50%); /* Center align the label */
}

/* Positioning for vertical tick labels */
.vertical-tick-label {
  left: 12px; /* Position to the right of the vertical tick mark */
  transform: translateY(-50%); /* Center align the label */
  text-align: right;
}

.draggable-box.focused-box, .draggable-box:hover {
  border: 4px solid yellow; /* Highlight color */
}

.yellow-border {
  border: 4px solid yellow; /* Highlight color */
}

.drag-handle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  z-index: 10; /* Ensure it's above other content */
}

.hoverable:hover {
  cursor: pointer;
}

.resizable-image { 
  max-width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: contain;
  object-position: center; /* Centers the image in the container */
  display: block; /* Remove default inline formatting */
  z-index: -1;
}

.sandbox-form-outline {
  /* display: flex;
  margin: auto;
  top: 138px;
  bottom: 80px; */
  outline: 2px solid black; /* Black outline */
  border-radius: 10px;
  overflow: auto;
  background: rgb(230, 230, 230);
}

.sandbox-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 20px;
  padding-bottom: 20px;
 }

.asset-library {
  position: fixed;   /* Fixed position relative to the viewport */
  right: 50px;       /* 50px from the right of the viewport */
  top: 160px;         /* 50px from the top of the viewport */
  bottom: 100px;      /* 50px from the bottom of the viewport */
  width: 17vw;      /* Fixed width of 200px */
  outline: 2px solid black; /* Black outline */
  border-radius: 10px; /* Rounded corners */
  overflow: auto;    /* Adds scroll if content overflows */
}

.asset-library-title {
  position: fixed;   /* Fixed position relative to the viewport */
  right: 110px;       /* 50px from the right of the viewport */
  top: 120px;         /* 50px from the top of the viewport */
  font-size: 20px;
}

.sandbox-generate-powerpoint-button {
  position: fixed;
  top: 700px;
  width: 200px;
  left: 50%; /* Position the element halfway across the screen or container */
  transform: translateX(-50%);
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust the gap as needed */
}

.image-container {
  display: flex;        /* Enable Flexbox */
  align-items: center;  /* Center vertically */
  justify-content: center; /* Center horizontally */
  width: calc(33.333% - 10px); /* Adjust for 3 images per row and account for gap */
  overflow: hidden;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover; /* This ensures images cover the area without losing aspect ratio */
}

.asset-library-button {
  position: fixed;   /* Fixed position relative to the viewport */
  right: 140px;       /* 50px from the right of the viewport */
  bottom: 50px;         /* 50px from the top of the viewport */
}

.competitor-add-remove-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  padding: 0;
  font-weight: bold;
}

.tombstone-add-remove-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  padding: 0;
  font-weight: bold;
}

.competitor-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.competitor-name {
  flex-grow: 1;
}

.add-competitor-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-competitor-form-input {
  width: 200px;
  margin-right: 10px; /* Space between input and button */
}

.vc-competitor-form-row {
  width: 350px;
  margin-right: 10px; /* Space between input and button */
}

.query-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.add-query-form-input {
  width: 590px;
  margin-right: 10px; /* Space between input and button */
}

.image-cell {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc((100%));
  cursor: pointer;
}

.image-center {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensures the image maintains its aspect ratio */
}

input[type="range"]::-webkit-slider-thumb {
  background: rgb(15, 99, 253) !important;
  border: 2px solid white;
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  background: rgb(15, 99, 253) !important;
  border: 2px solid white;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
  background: rgb(15, 99, 253) !important;
  border: 2px solid white;
}

input[type='range']::-webkit-slider-runnable-track {
  background: rgb(27, 48, 81); /* Color of the track */
}

.image-delete-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100; /* Ensure it's above the image */
  cursor: pointer;
  background-color: rgb(200, 200, 200);
  color: black;
  border-radius: 50%; /* Creates a circle */
  width: 15px; /* Size of the button */
  height: 15px; /* Size of the button */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px; /* Adjust as needed */
  padding: 0; /* Remove any default padding */
}

.industries-and-companies-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; /* Allow container to grow beyond viewport height */
  overflow-y: auto;
  margin-bottom: 80px;
  padding-top: 10px;
}

.editable-rows-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; /* Allow container to grow beyond viewport height */
  overflow-y: auto;
  margin-bottom: 80px;
  padding-top: 10px;
}

.add-company-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stepmark-pptOptions-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 80%;
  gap: 30px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.checkmark-form-container {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(3, 1fr);
  width: 80%;
  gap: 30px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
  margin-bottom: 80px;
}

.stepmark-pptContent-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 50px;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
}

.firm-overview-form-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 80%;
  margin-top: 50px;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
}