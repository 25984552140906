:root {
  /* Teal shades */
  --color-teal-100: #e9f5f5;
  --color-teal-200: #bfece2;
  --color-teal-300: #7bcfc7;
  --color-teal-400: #63b7b4;
  --color-teal-500: #469499;
  --color-teal-600: #35737a;
  --color-teal-700: #21535d;
  --color-teal-800: #193b43;
  --color-teal-900: #0d262c;
  --color-teal-1000: #06161a;

  /* Gray shades */
  --color-gray-25: #fefdff;
  --color-gray-50: #f8f8f8;
  --color-gray-100: #f0f2f5;
  --color-gray-200: #d9dee2;
  --color-gray-300: #c2c8cd;
  --color-gray-400: #acb2b8;
  --color-gray-500: #969da4;
  --color-gray-600: #808890;
  --color-gray-700: #6a727a;
  --color-gray-800: #555d65;
  --color-gray-900: #40474f;
  --color-gray-1000: #2b3138;

  /* Other colors */
  --color-accent-turquoise: #52dee6;
  --color-accent-sky-blue: #cbdeff;
  --color-accent-orange: #facfa2;
  --color-accent-purple: #e2d0f6;

  /* Semantic color variables */
  --color-primary: var(--color-teal-500);
  --color-secondary: var(--color-teal-300);
  --color-text-primary: var(--color-teal-1000);
  --color-text-secondary: var(--color-gray-700);
  --color-text-light: var(--color-gray-100);
  --color-background-light: white;
  --color-background-gray-light: var(--color-gray-25);
  --color-background-gray-medium: var(--color-gray-100);
  --color-background-teal-light: var(--color-teal-100);
  --color-link: var(--color-teal-400);

  /* Font weights */
  --font-weight-thin: 100;
  --font-weight-extra-light: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
  --font-weight-black: 900;
  --font-weight-extra-black: 1000;

  /* Font sizes */
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.25rem;
  --font-size-xl: 1.5rem;
  --font-size-xxl: 2rem;

  /* Spacing variables */
  --spacing-xxxs: 0.25rem;   
  --spacing-xxs: 0.5rem;     
  --spacing-xs: 0.75rem;    
  --spacing-sm: 1rem;       
  --spacing-md: 1.25rem;     
  --spacing-lg: 1.5rem;       
  --spacing-xl: 2rem;      
  --spacing-xxl: 3rem;     
  --spacing-xxxl: 4rem;
  
  /* Margin variables */
  --margin-xxxs: var(--spacing-xxxs);
  --margin-xxs: var(--spacing-xxs);
  --margin-xs: var(--spacing-xs);
  --margin-sm: var(--spacing-sm);
  --margin-md: var(--spacing-md);
  --margin-lg: var(--spacing-lg);
  --margin-xl: var(--spacing-xl);
  --margin-xxl: var(--spacing-xxl);
  --margin-xxxl: var(--spacing-xxxl);

  /* Padding variables */
  --padding-xxxs: var(--spacing-xxxs);
  --padding-xxs: var(--spacing-xxs);
  --padding-xs: var(--spacing-xs);
  --padding-sm: var(--spacing-sm);
  --padding-md: var(--spacing-md);
  --padding-lg: var(--spacing-lg);
  --padding-xl: var(--spacing-xl);
  --padding-xxl: var(--spacing-xxl);
  --padding-xxxl: var(--spacing-xxxl);

  /* Border radius variables */
  --border-radius-sm: 0.125rem; 
  --border-radius-md: 0.25rem;  
  --border-radius-lg: 0.5rem;   
  --border-radius-xl: 1rem;  
  --border-radius-xxl: 1.5rem;      
  --border-radius-xxxl: 2rem;  

  /* Box shadow variables */
  --box-shadow-sm: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  --box-shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --box-shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --box-shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);

  /* Line height variables */
  --line-height-tight: 1.25;
  --line-height-normal: 1.5;
  --line-height-relaxed: 1.75;

  /* Other variables */
  --left-navbar-width: 16rem;
}