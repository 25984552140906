/* GridAdjuster.css */
.grid-adjuster {
  font-family: Arial, sans-serif;
  position: fixed;   /* Fixed position relative to the viewport */
  left: 90px;       /* 50px from the right of the viewport */
  top: 480px;         /* 50px from the top of the viewport */
}

.adjuster {
  display: flex;
  align-items: center;
}

.rows-label {
  margin-right: 131px;
  font-size: 16px;
}

.cols-label {
  margin-right: 106px;
  font-size: 16px;
}

.controls {
  display: flex;
  align-items: center;
}

.arrow {
  background-color: rgb(250, 250, 250);
  color: rgb(27, 48, 81);
  border: none;
  cursor: pointer;
  width: 25px; /* Adjust size as needed */
  height: 25px; /* Adjust size as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.up::after {
  content: '▲'; /* Up arrow symbol */
}

.down::after {
  content: '▼'; /* Down arrow symbol */
}

.number-box {
  background-color: rgb(27, 48, 81);
  color: white;
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px; /* Adjust space around the number box */
  font-size: 20px;
  border-radius: 10px; /* Optional: for rounded corners */
}