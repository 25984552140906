.extracted-fields-viewer {
    font-family: Arial, sans-serif;
    max-width: 100%;
  }
  
  .extracted-fields-section {
    margin-bottom: 50px;
  }
  
  hr {
    border: none;
    border-top: 1px solid #ccc;
  }

  td {
    padding: 20px 10px;
  }
  
  .extracted-fields-section table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
  }

  .extracted-fields-section tr:nth-child(even) {
    background-color: var(--color-gray-100);
  }
  
  .extracted-fields-section tr:nth-child(odd) {
    background-color: white;
  }
  
  .extracted-fields-section tr:hover {
    background-color: var(--color-gray-200);
    transition: background-color 0.3s ease;
  }
  
  .field-name {
    width: 30%;
    font-weight: bold;
  }
  
  .response {
    width: 100%;
    margin: 0 auto;
    text-align: left;
    display: flex;
    align-items: center;
    text-align: left;
    min-height: 40px; /* Ensures consistent cell height */
  }

  

  .confidence-indicator {
    text-align: center;
    position: relative;
  }
  
  .confidence-indicator svg {
    color: #FF8C00; /* Darker orange for better contrast */
    vertical-align: middle;
  }
  
  .confidence-indicator:hover .tooltip {
    visibility: visible;
  }
  
  .tooltip {
    visibility: hidden;
    position: absolute;
    background: white;
    border: 1px solid #ddd;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 0.875rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: max-content;
    max-width: 200px;
    z-index: 10;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .remove-alert-button {
    background-color: var(--color-teal-200);
    width: 100px;
    border: none;
    margin-top: var(--margin-xxs);
    border-radius: var(--border-radius-md);
  }

  .remove-alert-button:hover {
    cursor: pointer;
  }
  
  .icon-cell {
    text-align: center;
    padding: 8px 0;  /* Add some vertical padding */
  }
  
  .field-row-icon {
    font-size: var(--font-size-xl);
    visibility: hidden;
    vertical-align: middle; /* Helps with vertical alignment */
  }

  .field-row-icon:hover {
    cursor: pointer;
  }
  
  tr:hover .field-row-icon {
    visibility: visible;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 300px;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
  }
  
  .close-button {
    align-self: flex-end;
    margin-bottom: 10px;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: black;
  }
  
  .pdf-viewer {
    flex-grow: 1;
    overflow: auto;
  }

  .row-icons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: var(--spacing-lg);
    padding-right: var(--padding-lg);
  }